@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");


:root{
    --choose_theme: #9ecb39;
    --background:#151f60;
    --arrow: #fc6759;
    --navbar_background:transparent;
    --logo_color:#fc6759;
    --hamburger_color: #fc6759;
    --link_number_color: #fc6759;
    --link_color: white;
    --button_background_color: transparent;
    --button_button_color: #fc6759;
    --button_border_color: 2px solid #fc6759;
    --intro_hi_color: #fc6759;
    --my_name_color:#ccd6f6;
    --slogan_color:#8892b0;
    --paragraph_color: #ccd6f6;
    --section_heading_color:#ccd6f6;
    --section_number_color:#fc6759;
    --card_border_color:1px solid #55f7f7;
    --card_folder_color:#fc6759;
    --card_nav_icon_color:white;
    --card_title_color:#ccd6f6; 
    --card_technology_color:#8892b0;
    --footer_icon_color:#ccd6f6;
    --footer_email_color:#ccd6f6;
    --footer_line_color:2px solid #fc6759;
    --footer_background_color: transparent;
    --footer_copyright_color:#ccd6f6;
    --particle:#55f7f7;
    --scroll_color: #fc6759;





}
.choose_theme{color: var(--choose_theme) !important;}
.background{                                                                    }
.arrow{color:var(--arrow);                                                      }
.navbar_background{background-color: var(--navbar_background) !important;       }
.logo_color{fill: var(--logo_color);                                            }
.hamburger_color{background-color: var(--hamburger_color);                      }
.link_number_color{color: var(--link_number_color);                             }
.link_color{color: var(--link_color) !important;                                }
.button_background_color{background:var(--button_background_color);             }
.button_button_color{color:var(--button_button_color);                          }
.button_border_color{border: var(--button_border_color);                        }
.intro_hi_color{color: var(--intro_hi_color);                                   }
.my_name_color{color: var(--my_name_color);                                     }
.slogan_color{color:var(--slogan_color)                                         }
.paragraph_color{color: var(--paragraph_color) !important;                      }
.section_heading_color{color: var(--section_heading_color);                     }
.section_number_color{color: var(--section_number_color);                       }
.card_border_color{border: var(--card_border_color);                            }
.card_folder_color{color: var(--card_folder_color);                             }
.card_title_color{color: var(--card_title_color);                               }
.card_nav_icon_color{color: var(--card_nav_icon_color);                         }
.card_technology_color{color: var(--card_technology_color);                     }
.footer_icon_color{color: var(--footer_icon_color);                             }
.footer_email_color{color:var(--footer_email_color)                             }
.footer_line_color{border-left: var(--footer_line_color);                       }
.footer_background_color{background-color: var(--footer_background_color);      }
.footer_copyright_color{color: var(--footer_copyright_color);                   }
.particle{                                                                      }
/* width */
::-webkit-scrollbar {width: 5px;}
/* Track */
::-webkit-scrollbar-track {background: transparent}
/* Handle */
::-webkit-scrollbar-thumb {background: var(--scroll_color);}





body{
    scroll-behavior: smooth;
    background: var(--background);
    font-family: "Open Sans", sans-serif;
    color: #fff;
    height: 100vh;


}

.container{
    max-width: 990px !important;
}

h3{
    font-weight: 700;
    color: #ccd6f6;
}





h1{ font-size: 3.7rem;}


*{
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}





@media screen and  (max-width: 576px) { 

h1{
    font-size: 2rem
}


 }

@media screen and  (max-width: 768px) {}
@media screen and  (max-width: 992px) {}
@media screen and  (max-width: 1200px) {}
@media screen and  (max-width: 1400px) {}



